<template>
  <v-container fluid>
    <!-- Section Path File/Modul -->
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModul"
    ></v-breadcrumbs>

    <!-- Section Filtering Input/Select/Button -->
    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6' : 'px-1'">
      <v-row align="center">
        <!-- Column Select Station -->
        <v-col class="col-input-filter">
          <v-select
            v-on:change="selectValueStation"
            :items="itemStation"
            label="Station"
            prepend-inner-icon="mdi-store-marker"
            hide-details
            solo
            dense
          ></v-select>
        </v-col>

        <!-- Column Select Month -->
        <v-col class="col-input-filter">
          <v-menu
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
            v-model="modalCalendar"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormat"
                label="Date"
                prepend-inner-icon="mdi-calendar-month"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
                solo
                dense
              ></v-text-field>
            </template>
            <v-date-picker
              v-on:change="selectDate"
              v-model="dateFormat"
              @input="modalCalendar = false"
              type="month"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4">
          <v-btn
            :disabled="valStation == null"
            class="white--text btn-generate button mr-2 mt-3"
            solo
            dense
            normal
            @click="GenerateTable"
          >
            GENERATE REPORT
          </v-btn>

          <v-btn
            class="white--text btn-upload button mt-3"
            solo
            dense
            normal
            @click="UpdateFile"
          >
            UPLOAD FILE
          </v-btn>

        </v-col>
      </v-row>

      <v-card class="pa-4" style="max-height: calc(100vh - 230px);overflow-y:auto;">
        <!-- Mini Title (Table Title) -->
        <v-subheader class="subTitle black--text font-weight-bold">
          CALIBRATION RECORD FOR {{ this.valStation }} ({{ convertUcase(this.dateSelected) }})
        </v-subheader>

        <!-- Table -->
        <v-data-table
          :headers="thead_calibration"
          :items="tbody_calibration"
          :header-props="{ sortIcon: null }"
          class="elevation-1 tableCalibration headerDtSarawak"
          :items-per-page="5"
          no-select-on-click
          :custom-sort="customSort"
        >
          <template v-slot:[`item.datetimeUpload`]="{ item }">
            <!-- {{ item.datetime_a }} -->
            <span v-html="item.datetimeUpload"></span>
          </template>
          <template v-slot:[`item.fileDoc`]="{ item }">
            <v-icon
              color="#02b2c3"
              v-html="item.fileDoc"
              @click="openFileDoc(item)"
            >
            </v-icon>
          </template>
          <template v-slot:[`item.delDetails`]="{ item }">
            <v-icon
              color="red"
              v-html="item.delDetails"
              @click="deleteItem(item)"
            >
            </v-icon>
          </template>
        </v-data-table>

      </v-card>

      <v-dialog v-model="dialogConfirmDelete" width="500">
        <v-card>
          <v-card-title class="text-h5" style="background:#106f79;color:white;">
            Delete Calibration Record
            <v-icon style="color:white;margin-left:15px;"
              >mdi-text-box-check-outline</v-icon
            >
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            Confirm to delete calibration record?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="confirmDelete()">
              YES
            </v-btn>
            <v-btn color="secondary" text @click="dialogConfirmDelete = false">
              NO
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogSucceedDelete" width="500">
        <v-card>
          <v-card-title class="text-h5" style="background:#106f79;color:white;">
            Delete Calibration Record
            <v-icon style="color:white;margin-left:15px;"
              >mdi-delete</v-icon
            >
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            Calibration record deleted successfully.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialogSucceedDelete = false">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogErrorPending" width="500">
        <v-card>
          <v-card-title class="text-h5 red lighten-2" style="color:white;">
            Warning!
            <v-icon style="color:white;margin-left:15px;">mdi-alert</v-icon>
          </v-card-title>

          <v-card-text style="padding:20px 24px;font-weight:bold;">
            Something went wrong. Please try again.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              style="color:red !important;"
              color="primary"
              text
              @click="dialogErrorPending = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    pathModul: [
      {
        text: "Home",
        disabled: false,
        // href: "/",
        href: '/Mapviewer',
      },
      {
        text: "Report - Calibration Record",
        disabled: true,
        href: "/Operational/Calibration",
      },
    ],
    itemStation: [],
    dateFormat: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 7),
    menu: false,
    modalCalendar: false,
    menu2: false,

    // Variable/Object for value filtering
    valStation: null,
    valDate: null,

    // Title - Subtitle After Generate Data
    stationID: null,
    dateSelected: null,
    monthText: [
      "Null",
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],

    dialogConfirmDelete: false,
    dialogSelect: false,
    dialogSucceedDelete: false,
    dialogErrorPending: false,
    delId: '',

    // DataTable
    thead_calibration: [
      {
        text: "Station ID",
        value: "stationid",
        divider: true,
        align: "center",
        sortable: false,
      },
      {
        text: "Date of Calibration",
        value: "datetimeUpload",
        divider: true,
        align: "center",
      },
      { text: "Uploader", value: "uploader", divider: true, align: "center" },
      {
        text: "Document",
        value: "fileDoc",
        divider: true,
        align: "center",
        sortable: false,
      },
      {
        text: "Delete",
        value: "delDetails",
        divider: true,
        align: "center",
        sortable: false,
      },
    ],
    tbody_calibration: [],
  }),
  methods: {
    
    convert12Hours(time) {
          var H = +time.substr(0, 2);
          var h = H % 12 || 12;
          var ampm = (H < 12 || H === 24) ? "AM" : "PM";
          time = h + time.substr(2, 3) + time.substr(5, 3) + ' ' + ampm;
          return time;
      },

      convertDateFormat(date){
          var dd = date.split("-")[2];
          var mm = date.split("-")[1];
          var yyyy = date.split("-")[0];
          date = dd + "/" + mm + "/" + yyyy;
          return date;
      },

      convertUcase(text){

      // console.log(text)

      if (text != null){

        let t_ucase = text.toUpperCase();

        return t_ucase;

        }
      },

    load() {
      this.getStation();
    },

    getStation() {
      axios
        .get(this.globalUrl+"mqm2/miqims/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          this.itemStation = [];

          for (let i = 0; i < response.data.length; i++) {
            this.itemStation.push(
              response.data[i].STATION_ID + " - " + response.data[i].LOCATION
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Get value from Selected Station
    selectValueStation: function(e) {
      // console.log(e);
      this.valStation = e;
    },

    // Get value from Selected Date
    selectDate: function(e) {
      this.valDate = e;
    },

    getDataCalibration(station, year, month) {
      console.log(month);
      axios
        .get(
          this.globalUrl+"mqm2/calib/data?stationid=" +
            station +
            "&year=" +
            year +
            "&month=" +
            month,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          let displayData = [];

          for (let i = 0; i < response.data.length; i++) {
            response.data[i]["datetimeUpload"] = this.convertDateFormat(
              response.data[i]["datetimeUpload"]
            );
          }

          response.data.forEach(function(res) {
            // if (res["datetimeUpload"] != null) {
            //     res["datetimeUpload"] = res["datetimeUpload"].replaceAll('T', '<br>');
            //     // res["datetimeUpload"] = this.convertDateFormat(res["datetimeUpload"]);
            // }

            res["fileDoc"] = "mdi-file-document";
            res["delDetails"] = "mdi-delete";

            // if (res["datetimeUpload"].split("-")[1] == month) {
            //     displayData.push(res);
            // }

            displayData.push(res);
          });
          this.tbody_calibration = displayData;
          console.log(this.tbody_calibration);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openFileDoc: function(e) {
      console.log(e);
      // window.location.href = e.filepath;
      window.open(e.filepath, "_blank");
    },

    deleteItem: function(e) {
      console.log(e);

      this.dialogConfirmDelete = true;
      this.delId = e.id

      // axios
      //   .get(this.globalUrl+"mqm2/calib/del?id=" + e.id, {
      //     headers: {
      //       Authorization: "Bearer " + this.$store.getters.user.token,
      //     },
      //   })
      //   .then((response) => {
      //     console.log("Success Deleted");
      //     this.GenerateTable();
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     console.log("Unsuccess...");
      //   });
    },

    confirmDelete(){

    this.dialogConfirmDelete = false;

        axios
        .get(this.globalUrl+"mqm2/calib/del?id=" + this.delId, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          console.log("Success Deleted");
          this.dialogSucceedDelete = true;
          this.GenerateTable();
        })
        .catch((error) => {
          console.log(error);
          this.dialogErrorPending = true;
          console.log("Unsuccess...");
        });
    },

    GenerateTable() {
      // Keluarkan Details SubText
      this.stationID = this.valStation.split(" - ")[0];
      for (let i = 0; i < this.monthText.length; i++) {
        if (i == this.dateFormat.split("-")[1]) {
          this.dateSelected =
            this.monthText[i] + " " + this.dateFormat.split("-")[0];
        }
      }

      // Unhide checkbox on header
      document.querySelector(".subTitle").style.display = "block";
      let chk = document.querySelectorAll(".checkbox-header-dailyReport");
      for (let i = 0; i < chk.length; i++) {
        chk[i].style.display = "contents";
      }

      this.getDataCalibration(
        this.stationID,
        this.dateFormat.split("-")[0],
        this.dateFormat.split("-")[1]
      );
    },

    UpdateFile: function() {
      window.location.href = "/Operational/CalibrationUpload";
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

      console.log(index);

      items.sort((a, b) => {
        if (index[0] == "datetimeUpload") {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
    
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

/* Section Path File/Modul */
.pathModul {
  padding-bottom: 0px;
}

.pathModul > li:nth-child(3) > a,
.pathModul > li:nth-child(3) > div {
  color: black !important;
}

/* Section Filtering Input/Select/Button */
.container-filtering {
  padding: 0px;
}

.btn-download-table {
  margin-right: 8px;
}

/* Section Generate Result Datatable Vue */
.subTitle {
  text-align: center !important;
  background: white;
  // color: #0187bc;
  display: none;
}

.form-datatable {
  padding: 10px 0px 0px 0px;
}

// .tableCalibration > div:nth-child(1) > table > thead > tr > th{
//     background: #4495D1;
//     color: white !important;
//     font-weight: bold;
// }

.tableCalibration > .v-data-table__wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 550px;
}
</style>